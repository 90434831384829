<template>
  <div>
    <b-row>
      <b-col md="3">
        <input-select-search
          ref="tipoFiltro"
          v-model="localFiltro.tipoFiltro"
          :label="$t(`ACADEMICO.TIPO_FILTRO`)"
          :options="opcoes.tiposFiltro"
        />
      </b-col>
      <b-col md="3" v-if="localFiltro.tipoFiltro === 0">
        <input-select-search
          ref="matriculaId"
          v-model="localFiltro.matriculaId"
          :label="$t(`ACADEMICO.MATRICULAS`)"
          :options="opcoes.matriculas"
        />
      </b-col>
      <b-col md="3" v-if="localFiltro.tipoFiltro === 1">
        <input-select-search
          ref="cursoId"
          v-model="localFiltro.cursoId"
          :label="$t(`ACADEMICO.CURSO`)"
          :options="opcoes.cursos"
        />
      </b-col>
      <b-col md="3" v-if="localFiltro.tipoFiltro === 1">
        <input-select-search
          ref="grupoTurmaId"
          v-model="localFiltro.grupoTurmaId"
          :label="$t(`ACADEMICO.GRUPO_TURMA`)"
          :options="opcoes.grupoTurmas"
          :isDisabled="desabilitar.grupoTurma"
        />
      </b-col>
      <b-col md="3" v-if="localFiltro.tipoFiltro === 1">
        <input-select-search
          ref="turmaId"
          v-model="localFiltro.turmaId"
          :label="$t(`ACADEMICO.TURMA`)"
          :options="opcoes.turmas"
          :isDisabled="desabilitar.turma"
        />
      </b-col>
      <b-col cols="12" md="auto" v-if="localFiltro.tipoFiltro === 1" class="d-flex align-items-center botao-acao-filtro">
        <input-checkbox
          v-model="localFiltro.somentePagamentosEmDia"
          type="boolean"
          :label="$t('ACADEMICO.SOMENTE_PAGAMENTOS_EM_DIA')"
          :placeholder="$t('ACADEMICO.SOMENTE_PAGAMENTOS_EM_DIA')"
        />
      </b-col>
      <b-col cols="12" md="auto" class="botao-acao-filtro">
        <b-btn @click="filtrar" variant="primary" class="mr-2 w-100">
          {{ $t('ACADEMICO_MATRICULAS.LISTAR_CONTRATOS') }}
        </b-btn>
      </b-col>
      <b-col cols="12" md="auto" class="botao-acao-filtro mt-3">
        <b-btn @click="limparFiltro" class="mr-2  w-100">{{
          $t('GERAL.LIMPAR')
        }}</b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import MatriculaService from '@/common/services/matriculas/matriculas.service.js';

import CursoService from '@/common/services/academico/curso.service';
import TurmaService from '@/common/services/academico/turma.service';
import GrupoTurmaService from '@/common/services/grupoTurma/grupo-turma.service';

// Components:
import { InputSelectSearch, InputCheckbox } from '@/components/inputs';

export default {
  name: 'filtroRematricula',
  components: {
    InputSelectSearch,
    InputCheckbox,
  },
  data() {
    return {
      desabilitar: {
        turma: true,
        grupoTurma: true,
      },
      localFiltro: {
        tipoFiltro: '',
        matriculaId: '',
        cursoId: '',
        grupoTurmaId: '',
        turmaId: '',
        somentePagamentosEmDia: false,
      },
      opcoes: {
        matriculas: [],
        cursos: [],
        grupoTurmas: [],
        turmas: [],
        tiposFiltro: [
          {
            text: 'Por matricula',
            value: 0,
          },
          {
            text: 'Por turma',
            value: 1,
          },
        ],
      },
    };
  },
  mounted() {
    this.listarMatriculasSemPaginacao();
    this.getCursos();
    this.$emit('refs', this.$refs);
  },
  watch: {
    'localFiltro.cursoId': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getGrupoTurmas();
          this.opcoes.turmas = [];
          this.desabilitar.turma = true
        }
      },
      deep: true,
    },
    'localFiltro.grupoTurmaId': {
      handler(newValue) {
        if (newValue !== undefined) {
          this.getTurmasPorCursoGrupoTurma();
        }
      },
      deep: true,
    },
    localFiltro: {
      handler(newValue) {
        let filtro;
        if (newValue.tipoFiltro == 0) {
          filtro = {
            matriculaId: this.localFiltro.matriculaId,
            somentePagamentosEmDia: false,
            cursoId: '',
            grupoTurmaId: '',
            turmaId: '',
          };
        } else {
          filtro = {
            cursoId: this.localFiltro.cursoId ?? '',
            grupoTurmaId: this.localFiltro.grupoTurmaId ?? '',
            turmaId: this.localFiltro.turmaId ?? '',
            somentePagamentosEmDia: this.localFiltro.somentePagamentosEmDia,
            matriculaId: '',
          };
        }
        this.$emit('atualizar-filtro', filtro);
      },
      deep: true,
    },
  },
  methods: {
    listarMatriculasSemPaginacao() {
      this.$store.dispatch(START_LOADING);
      MatriculaService.listarSemPaginacao()
        .then(({ data }) => {
          if (data.length) {
            data = data.map((row) => {
              return {
                ...row,
                text: `${row.codigoMatricula} - ${row.nome}`,
                value: row.id,
              };
            });
            this.opcoes.matriculas = data;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getCursos() {
      this.$store.dispatch(START_LOADING);
      CursoService.listar()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.cursos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getGrupoTurmas() {
      this.$store.dispatch(START_LOADING);
      GrupoTurmaService.buscarPorCursoIdAnoLetivoId(
        this.localFiltro.cursoId,
        this.localFiltro.anoLetivoId
      )
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.grupoTurmas = data;

          if (!this.id) {
            this.desabilitar.grupoTurma = false;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getTurmasPorCursoGrupoTurma() {
      this.$store.dispatch(START_LOADING);
      TurmaService.buscarPorCursoIdGrupoTurmaId(this.localFiltro)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.turmas = data;
          this.habilitarCampo('turma');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    habilitarCampo(key) {
      this.desabilitar[key] = false;
    },
    // FUNÇÕES FILTRO:
    validarFiltro() {
      return helpers.validarFormulario(this.$refs);
    },

    filtrar() {
      this.$emit('filtrar', this.localFiltro);
    },

    limparFiltro() {
      this.localFiltro = {
        tipoFiltro: '',
        matriculaId: '',
        curso: '',
        grupoTurma: '',
        turma: '',
        somentePagamentosEmDia: false,
      };
      this.$emit('limpar', this.localFiltro);
    },
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 15px;
}
</style>
