<template>
  <div>
    <topbar
      :titulo="$t('ACADEMICO_MATRICULAS.REMATRICULAS')"
      :subtitulo="$t('ACADEMICO_MATRICULAS.REMATRICULAS_DESCRICAO_PAGE')"
      :breadcrumbs="breadcrumbs"
    />

    <filtro-rematricula
      @limpar="limparFiltro"
      @filtrar="filtrar"
      @atualizar-filtro="atualizarFiltro"
    />

    <div class="d-flex flex-column flex-sm-row justify-content-between py-4">
      <h5>
        {{ $t('ACADEMICO.LISTA_DE_CONTRATOS') }}
      </h5>
      <b-btn-group class="d-flex gap-2 flex-column flex-sm-row">
        <b-btn
          variant="info"
          @click="gerarRematriculas"
          :disabled="contratos.length == 0"
        >
          {{ $t('ACADEMICO.GERAR_REMATRICULA') }}
        </b-btn>
        <b-btn
          variant="info"
          @click="ativarContratos"
          :disabled="contratos.length == 0"
        >
          {{ $t('ACADEMICO.ATIVAR_CONTRATOS') }}
        </b-btn>
        <b-btn
          variant="info"
          @click="imprimirContratos"
          :disabled="contratos.length == 0"
        >
          {{ $t('ACADEMICO.IMPRIMIR_CONTRATOS') }}
        </b-btn>
        <b-btn
          variant="info"
          @click="imprimirFichaDeMatricula"
          :disabled="contratos.length == 0"
        >
          {{ $t('ACADEMICO.IMPRIMIR_FICHA_REMATRICULA') }}
        </b-btn>
      </b-btn-group>
    </div>

    <tabela-contratos-rematricula :items="contratos" />

    <paginacao v-model="paginacao" :total="paginacao.total" @input="filtrar" />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import pdf from '@/common/utils/pdf';

// Services:
import ContratosService from '@/common/services/contratos/contratos.service';

// Components:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
import FiltroRematricula from '@/views/academico/rematriculas/components/FiltroRematricula';
import TabelaContratosRematricula from '@/views/academico/rematriculas/components/TabelaContratosRematricula';

import Paginacao from '@/components/paginacao/Paginacao';

export default {
  name: 'rematriculas',
  components: {
    Topbar,
    Paginacao,
    FiltroRematricula,
    TabelaContratosRematricula,
  },
  data() {
    return {
      contratos: [],
      filtro: {},
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO.ACADEMICO') },
        { titulo: this.$t('ACADEMICO_MATRICULAS.REMATRICULAS') },
      ],
    };
  },
  methods: {
    //  rematricula
    gerarRematriculas() {
      if (this.contratos.length === 0) return;
      this.$store.dispatch(START_LOADING);
      ContratosService.gerarRematricula(this.filtro)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    imprimirContratos() {
      this.$store.dispatch(START_LOADING);
      ContratosService.imprimirContratosRematricula(this.filtro)
        .then(({ data }) => {
          if (data.documento) {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            // converte o array em blob
            let blob = new Blob([bytes], { type: 'application/pdf' });

            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    imprimirFichaDeMatricula() {
      this.$store.dispatch(START_LOADING);
      ContratosService.imprimirFichaDeMatricula(this.filtro)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], { type: 'application/pdf' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    ativarContratos() {
      // if (this.contratos.length === 0) return;
      this.$store.dispatch(START_LOADING);
      ContratosService.ativarContratos(this.filtro)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // filtro
    filtrar() {
      this.$store.dispatch(START_LOADING);
      ContratosService.listarContratosParaRematriculaPaginado(
        this.filtro,
        this.paginacao
      )
        .then(({ data }) => {
          this.contratos = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          this.contratos = [];
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    atualizarFiltro(filtro) {
      this.filtro = filtro;
    },

    limparFiltro() {
      this.contratos = [];
      this.paginacao = {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      };
      this.filtro = {
        matriculaId: '',
        curso: '',
        grupoTurma: '',
        turma: '',
        somentePagamentosEmDia: '',
      };
    },

  },
};
</script>

<style scoped>
.gap {
  gap: 10px;
}
.btn:disabled {
  cursor: not-allowed;
}
</style>
