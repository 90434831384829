<template>
  <div>
    <b-table
      :fields="campos"
      :items="items"
      show-empty
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      responsive
      striped
      hover
    />
  </div>
</template>

<script>
export default {
  name: 'tabelaContratosRematricula',
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      campos: [
        {
          key: 'codigo',
          label: this.$t('ACADEMICO.CODIGO'),
        },
        {
          key: 'status',
          label: this.$t('GERAL.STATUS'),
        },
        {
          key: 'nomeAluno',
          label: this.$t('ACADEMICO.ALUNO'),
        },
        {
          key: 'idioma',
          label: this.$t('ACADEMICO.IDIOMA'),
        },
        {
          key: 'anoLetivo',
          label: this.$t('ACADEMICO.ANO_LETIVO'),
        },
        {
          key: 'tipoContrato',
          label: this.$t('ACADEMICO.TIPO_CONTRATO'),
        },
        {
          key: 'grupoTurmaDescricao',
          label: this.$t('ACADEMICO.GRUPO_TURMA'),
        },
      ],
    };
  },
};
</script>

<style scoped></style>
